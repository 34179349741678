import React from "react";
import { AppLayout } from "../../layout/Index";
import {
  PartnersWrapper,
  PartnersContainer,
  CardContainer,
  Card,
  CardDesc,
} from "./style";

const Teamdata = [
  {
    img: "assets/imgs/ourpartners/via-btc-WBG.webp",
  },
  {
    img: "assets/imgs/ourpartners/Binance-Logo-PNG_edited.webp",
  },
  {
    img: "assets/imgs/ourpartners/fuze_edited.webp",
  },
  {
    img: "assets/imgs/ourpartners/fireblocks-WBG.webp",
  },
];

const index = () => {
  return (

      <PartnersWrapper>
        <PartnersContainer>
            <h1>
              Our <span>Partners</span>
            </h1>
          <CardContainer>
            {Teamdata.map((item, key) => (
              <Card>
                <CardDesc>
                  <img src={item.img} alt="" />
                </CardDesc>
              </Card>
            ))}
          </CardContainer>
        </PartnersContainer>
      </PartnersWrapper>
  
  );
};

export default index;
