import React from "react";
import { AppLayout } from "../../layout/Index";
import LandingPage from "../../layout/landing/Index";
import Mining from "./Mining";
import { BsTwitter, BsLinkedin } from "react-icons/bs";
import {
  TeamWrapper,
  TeamContainer,
  AdvisorContainer,
  CardContainer,
  CardContent,
  Card,
  CardIcons,
  CardDesc,
} from "./style";

const Teamdata = [
  {
    title: "Vickaash Agarwal",
    subtitle: "Chief Executive Officer",
    img: "assets/imgs/team/1.png",
    linkLinkedin: "https://www.linkedin.com/in/vickaashagarwal",
    linkTwitter: "",
    bio:"",
  },
  {
    title: "Captain Ajay Chauhan",
    subtitle: "",
    img: "assets/imgs/team/2.png",
    linkLinkedin: "https://www.linkedin.com/in/ajay-chauhan-221b8942",
    bio:"",
  },

  {
    title: "Mrs. Sonal Shinde",
    subtitle: "Sr. HR Manager",
    img: "assets/imgs/team/sonal.png",
    linkLinkedin: "https://www.linkedin.com/in/sonal-shinde-807b8a225",
    bio:"",
  },
  {
    title: "Aikanysh Talantova",
    subtitle: "",
    img: "assets/imgs/team/aikanysh.png",
    linkLinkedin: "http://linkedin.com/in/aikanysh-talantova-699a0b17a",
    bio:"",
  },
  {
    title: "CA. Shraddha Modi",
    subtitle: "",
    img: "assets/imgs/team/shradda.png",
    linkLinkedin: "https://www.linkedin.com/in/Shraddha-modi-74b611b/",
    bio:"",
  },
  {
    title: "Mr. Fadi Zaknoun",
    subtitle: "",
    img: "assets/imgs/team/fadizaknoun.jpg",
    linkLinkedin: "https://www.linkedin.com/in/fadi-zaknoun1",
    bio:"",
  },
  {
    title: "Rybakova Kristina",
    subtitle: "",
    img: "assets/imgs/team/rybakovakristina.png",
    linkLinkedin: "",
    bio:"",
  }

];
const AdvisorData = [
  
  {
    title: "Manaw Modi",
    img: "assets/imgs/advisor/3.png",
    linkLinkedin: "https://www.linkedin.com/in/manawmodi",
    linkTwitter: "",
  },
  {
    title: "Dr Parimal Merchant",
    img: "assets/imgs/advisor/4.png",
    linkLinkedin: "https://www.linkedin.com/in/dr-parimal-merchant-4b019116",
    linkTwitter: "",
  },

  {
    title: "Amalia 未来 Grochal",
    img: "assets/imgs/advisor/6.png",
    linkLinkedin: "https://www.linkedin.com/in/amalia-grochal/",
    linkTwitter: "",
  },
 
  
];

const index = () => {
  return (
    <AppLayout>
      <TeamWrapper>
        <TeamContainer>
          <h1>
            Our <span>Team</span>
          </h1>
          <CardContainer>
            {Teamdata.map((item, key) => (
              <Card>
                <CardDesc>
                  <img src={item.img} alt="" style={{ borderRadius: '50%' }}/>
                  <CardContent>
                    <p>{item.title}</p>
                    <p>{item.subtitle}</p>
                  </CardContent>
                </CardDesc>
                <CardIcons>
                  {item.linkTwitter && (
                    <a target="_blank" href={item.linkTwitter}>
                      <BsTwitter fontSize="30px" />
                    </a>
                  )}

                  {item.linkLinkedin && (
                    <a target="_blank" href={item.linkLinkedin}>
                      <BsLinkedin fontSize="30px" />
                    </a>
                  )}
                </CardIcons>
              </Card>
            ))}
          </CardContainer>
        </TeamContainer>
        <AdvisorContainer>
          <h1>
            Our <span>Advisor</span>
          </h1>
          <CardContainer>
            {AdvisorData.map((item, key) => (
              <Card>
                <CardDesc>
                  <img src={item.img} alt=""   />
                  <CardContent>
                    <p>{item.title}</p>
                  </CardContent>
                </CardDesc>
                <CardIcons>
                  {item.linkLinkedin && (
                    <a target="_blank" href={item.linkLinkedin}>
                      <BsLinkedin fontSize="30px" />
                    </a>
                  )}

                  {item.linkTwitter && (
                    <a target="_blank" href={item.linkTwitter}>
                      <BsTwitter fontSize="30px" />
                    </a>
                  )}
                </CardIcons>
              </Card>
            ))}
          </CardContainer>
        </AdvisorContainer>
      </TeamWrapper>
      <Mining />
   
    </AppLayout>
  );
};

export default index;
