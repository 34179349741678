import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SocialMediaIcon from './components/socialmedia';

// Pages Elements
import Home from './pages/home/Index';
import Rigs from './pages/rigs/Index';
import About from './pages/about/Index';
import InvestmentPage from './pages/investment/Index';
import Blog from './pages/blog/Index';
import Roadmap from './pages/roadmap/Index';
import Nft from './pages/ordinals/Index';
import Stake from './pages/stake/Index';
import Team from './pages/team';
import HashratePage from './pages/hashrate/Index';
import Ourpartners from "./pages/ourpartners";
import Videos from "./pages/videos";
const App = () => {
  return (
    <Router>
              <SocialMediaIcon /> {/* Sosyal medya ikonlarını tüm sayfalarda göstermek için burada ekleyin */}

      <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/rigs' element={<Rigs />} />
          <Route path='/hashrate' element={<HashratePage />} />
          <Route path='/about' element={<About />} />
          <Route path='/investment' element={<InvestmentPage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/ordinals' element={<Nft />} />
          <Route path='/roadmap' element={<Roadmap />} />
          <Route path='/stake' element={<Stake />} />
          <Route path='/team' element={<Team />} />
          <Route path='/ourpartners' element={<Ourpartners />} />
          <Route path='/videos' element={<Videos/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
