import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export const headerTab = [
  {
    title: "HASHRATE",
    direct: "/hashrate",
  },
  {
    title: "ABOUT US",
    direct: "/about",
    submenu: [
      { title: "About Us", direct: "/about" },
      { title: "Team", direct: "/team" },
      { title: "Partners", direct: "/ourpartners" },
    ],
  },
  {
    title: "OUR BUSINESS",
    direct: "/business",
    submenu: [
      { title: "Mining & Hosting Service", direct: "/" },
      { title: "Digital Mining (Ordinals)", direct: "/ordinals" },
      { title: "Investment", direct: "/" },
      { title: "OCT Desk", direct: "/" },
    ],
  },
  {
    title: "MEDIA",
    direct: "/media",
    submenu: [
      { title: "Press", direct: "/press" },
      { title: "Event", direct: "/event" },
    ],
  },
  {
    title: "CONTACT US",
    direct: "/contact",
  },
];

type SidebarProps = {
  isshow: boolean;
  onClose: () => void;
};

const Sidebar = ({ isshow, onClose }: SidebarProps) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = React.useState<string | null>(null);

  const toggleMenu = (menu: string) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  return (
    <div className={`fixed z-[999] max-w-[325px] w-full top-0 transition-all duration-300 ${isshow ? 'right-0' : '-right-[325px]'} bg-[#2c2d31] text-white h-full md:hidden`}>
      <div className="flex justify-end mt-3 mr-4 text-3xl">
        <span onClick={onClose} className="cursor-pointer">✕</span>
      </div>
      <nav className="flex flex-col gap-5 p-5">
        {headerTab.map((item, key) => (
          <div key={key}>
            <Link
              to={item.direct}
              className={`block px-4 py-2 text-lg font-semibold text-white transition-all duration-300 ${location.pathname === item.direct ? 'text-[#3fcdc3]' : ''}`}
              onClick={() => item.submenu && toggleMenu(item.title)}
            >
              {item.title}
            </Link>
            {item.submenu && (
              <div className={`ml-4 ${activeMenu === item.title ? 'block' : ''}`}>
                {item.submenu.map((subItem, subKey) => (
                  <Link
                    key={subKey}
                    to={subItem.direct}
                    className="block px-4 py-2 text-sm text-white hover:bg-[#3fcdc3] hover:text-[#142c2a] transition-all duration-300"
                  >
                    {subItem.title}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
        <a
          target="_blank"
          href="https://gamma.io/ordinals/collections/bluewheel-mining"
          className="block cursor-pointer w-full h-[40px] flex items-center justify-center rounded-md text-[#142c2a] bg-[#3fcdc3] font-semibold text-lg mt-5 transition-all duration-300"
        >
          Buy Ordinals
        </a>
      </nav>
    </div>
  );
};

export default Sidebar;
