import React from "react";
import { HomeWrapper } from "./style";
import { AppLayout } from "../../layout/Index";
import Hashrate from "../../components/Hashrate";
import Stoke from "../../components/chartsNew";
import {
  HashWrapper,
  HashContainer,
  HashContent,
  HashBtn,
  HashBody,
  HashHead,
  HashTable,
  HeadItem,
  BodyItem,
  TableContent,
} from "./style";

const InvestmentPage = () => {
  return (
    <AppLayout>
      <HomeWrapper>
        <HashWrapper>
          <HashContainer>
            <HashContent>
              <h1>Investment</h1>
            </HashContent>
            <TableContent className="topbar">
              <HashTable>
                <a>
                  Bluewheel Group has been investing in blockchain startups
                  since 2020, with over 200 startups funded. The founders are
                  general partners in Illuminati Capital Limited , a $50 million
                  USD private investment company, and Bluewheel Capital, a $20
                  million USD investment company.
                </a>
              </HashTable>
            </TableContent>
          </HashContainer>
        </HashWrapper>
      </HomeWrapper>
    </AppLayout>
  );
};

export default InvestmentPage;
