import React, { useEffect, useState } from "react";
import {
  HashWrapper,
  HashContainer,
  ButtonWrapper,
  HashContent,
  HashBtn,
  HashBody,
  HashHead,
  HashTable,
  HeadItem,
  BodyItem,
  TableContent,
} from "./style";
import axios from "axios";

const hashHeadData = [
  "Model",
  "Batch Realease Date",
  "Hashrate",
  "Electricity Efficiency",
  "Efficiency",
  "Daily Revenue",
  "Action",
];

type BodyData = {
  name: string;
  releaseDate: string;
  hashrate: number | null |string;
  powerConsumption: string;
  powerEfficiency: string;
  profitability1: string;
  profitability2: string;
};


const Hashrate = () => {
  const [hashRate, setHashRate] = useState<number | null>(null);
  const [hashRate2, setHashRate2] = useState<number | null>(null);
  const [bodyData, setBodyData] = useState<BodyData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get(
          "https://api.bluewheelmining.com/datas/machines/machines_stats_m054H0psEUTMwymowb06q86KTPuNQWRzzNjzepBf0d807021.json"
        );
        const { hashRate } = response1.data;
                const hashRateFormatted = (hashRate / 10 ** 150).toString().slice(0, 4); 

        setHashRate(hashRate);

       /* const response2 = await axios.get("../../dataSource/a.json"); // other machines here
        const { hashRate: hashRate2 } = response2.data;
       setHashRate2(hashRate2); */

        const newBodyData: BodyData[] = [
          {
            name: "Antminer S19J Pro+ Apr 2023",
            releaseDate: "Apr 2023",
            hashrate: hashRateFormatted+" Ph/s",
            powerConsumption: "3480 W",
            powerEfficiency: "24.00 W/TH",
            profitability1: " $5.34/day",
            profitability2: " $5.34/day",
          },
        ];

        setBodyData(newBodyData);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <HashWrapper>
      <HashContainer>
        <HashContent>
          <h1>Ordinals Hashrate Distribution</h1>
          <HashBtn>View All Pools</HashBtn>
        </HashContent>
        <TableContent className="topbar">
          <HashTable>
            <HashHead>
              {hashHeadData.map((item, key) => (
                <HeadItem key={key}>{item}</HeadItem>
              ))}
            </HashHead>

            {bodyData.map((item, key) => (
              <tr key={key}>
                <BodyItem>{item.name}</BodyItem>
                <BodyItem>{item.releaseDate}</BodyItem>
                <BodyItem>{item.hashrate}</BodyItem>
                <BodyItem>{item.powerConsumption}</BodyItem>
                <BodyItem>{item.powerEfficiency}</BodyItem>
                <BodyItem>{item.profitability1}</BodyItem>
                <BodyItem>
                  {" "}
                  <ButtonWrapper>Start Now</ButtonWrapper>
                </BodyItem>
              </tr>
            ))}
          </HashTable>
        </TableContent>
      </HashContainer>
    </HashWrapper>
  );
};

export default Hashrate;
