import { styled } from "styled-components";

export const PartnersWrapper = styled.div`
  
  margin-top: 100px;
  padding: 80px 0;
`;

export const PartnersContainer = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
`;


export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 80px;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  a {
    @media screen and (max-width: 425px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 280px;
  width: 100%;
  padding: 40px 0;
  border-radius: 20px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #5cf8e5;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
  img {
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: auto;
  }
`;

export const CardDesc = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  img{
    border-radius: 100px;
    width: 150px;
    height: auto;
  }
`;

