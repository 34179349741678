import React, { useEffect, useState } from "react";
import {
  TopbarWrapper,
  TopbarContainer,
  BarItems,
  Item,
  IemContainer,
  DescContainer,
} from "./style";
import { AiOutlineArrowDown } from "react-icons/ai";
import axios from "axios";


const Topbar = () => {
  interface TopbarItem {
    title: string;
    image: string;
    desc: string;
  }
  const [topbarData, setTopbarData] = useState<TopbarItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [difficulty, blocksize, totalbc, hashrate, price] =
          await Promise.all([
            axios.get("https://blockchain.info/q/getdifficulty"),
            axios.get(
              "https://api.blockchain.info/charts/blocks-size?timespan=1week&sampled=true&metadata=false&daysAverageString=1d&cors=true&format=json"
            ),
            axios.get("https://blockchain.info/q/totalbc"),
            axios.get("https://blockchain.info/q/hashrate"),
            axios.get("https://blockchain.info/q/24hrprice"),
          ]);

        const latestBlockSizeMB = parseFloat(
          blocksize.data.values[blocksize.data.values.length - 1].y
        );
        const latestBlockSizeGB = latestBlockSizeMB.toFixed(0).slice(0, -3);

        const totalBitcoin = (parseFloat(totalbc.data) / 1e8).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }
        );

        const roundedValue = (parseFloat(difficulty.data) / 1e12).toFixed(2);
        const formattedValue = roundedValue.replace(".", ".");

        const formattedDiff = (parseFloat(hashrate.data) / 1e9).toFixed(2);

        setTopbarData([
          {
            title: `${formattedValue} TH (Difficulty)`,
            image: "assets/imgs/topbar/1.png",
            desc: "",
          },
          {
            title: `${latestBlockSizeGB} GB (Blockchain Size)`,
            image: "assets/imgs/topbar/2.png",
            desc: "",
          },
          {
            title: `${totalBitcoin} (Total Circulating)`,
            image: "assets/imgs/topbar/3.png",
            desc: "",
          },
          {
            title: formattedDiff + " EH/s (Hashrate)",
            image: "assets/imgs/topbar/4.png",
            desc: "",
          },
          {
            title: "$ "+price.data + " (24h Price)",
            image: "assets/imgs/topbar/5.png",
            desc: "",
          },
        ]);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <TopbarWrapper>
      <TopbarContainer className="topbar ">
        <BarItems>
          {topbarData.length > 0 &&
            topbarData.map((item, key) => (
              <Item key={key}>
                <img src={item.image} alt="" />
                <IemContainer>
                  <p>{item.title}</p>
                  <DescContainer>
                    {item.desc ? <AiOutlineArrowDown color="DF4F50" /> : ""}
                    {item.desc ? <p>{item.desc}</p> : ""}
                  </DescContainer>
                </IemContainer>
              </Item>
            ))}
        </BarItems>
      </TopbarContainer>
    </TopbarWrapper>
  );
};

export default Topbar;
