import React from "react";
import { Link } from "react-router-dom";
import {
  FooterWrapper,
  FooterContainer,
  TabsContainer,
  TabContainer,
  FAboutContainer,
  FooterIcons,
  AboutDesc,
  FooterAuthority,
} from "./style";
import {
  BsFacebook,
  BsYoutube,
  BsTwitter,
  BsLinkedin,
  BsDiscord,
  BsTelegram,
  BsMedium,
  BsFilePdfFill,
} from "react-icons/bs";

const tabData = [
  {
    title: "Tools",
    content: ["Calculator", "Hashrate Converter", "Research"],
  },
  {
    title: "External",
    content: ["About", "Terms of Service", "Pitch Deck"],
  },
];

const tabData2 = [
  {
    title: "Mining",
    contentData: [
      { title: "Hashrate", url: "/hashrate" },
      { title: "Rigs", url: "/rigs" },
     
    ]
  },
  {
    title: "Ordinals",
    contentData: [
      { title: "Ordinals", url: "/ordinals" },
     
    ]
  },
  {
    title: "Company",
    contentData: [
      { title: "About", url: "/about" },
      { title: "Team", url: "/team" },
      { title: "Roadmap", url: "/roadmap" },
    ]
  },
  {
    title: "External",
    contentData: [
      { title: "Live Pool", url: "https://pool.binance.com/en/statistics?urlParams=m054H0psEUTMwymowb06q86KTPuNQWRzzNjzepBf0d807021" },
      
    ]
  },
];
const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <FAboutContainer>
          <img src="assets/imgs/logo.png" alt="" />
          <AboutDesc>
            <p>
              Building infrastructure to support the next generation of digital
              assets
            </p>
            <FooterIcons>
            <a target="_blank" href="https://t.me/bluewheelmining">
                <BsTelegram />
              </a>
              <a target="_blank" href="https://t.me/bluewheelminersann">
                <BsTelegram />
              </a>
              <a target="_blank" href="writeyourfacebook BLUEWHEEL FACEBOOK">
                <BsFacebook />
              </a>
              <a target="_blank" href="https://discord.gg/bluewheelmining">
                <BsDiscord />
              </a>
              <a target="_blank" href="https://twitter.com/BluewheelM">
                <BsTwitter />
              </a>
              <a target="_blank" href="https://medium.com/@bluewheelmining">
                <BsMedium />
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/bluewheel-mining-club/"
              >
                <BsLinkedin />
              </a>
            
            </FooterIcons>
          </AboutDesc>
        </FAboutContainer>
        <TabsContainer>
          {tabData2.map((item, key) => (
            <TabContainer key={`footertab${key}`}>
              <h3>{item.title}</h3>
              {item.contentData.map((item, index) => (
                  <a key={`content${index}`}>
                    <a href={item.url} rel="noopener noreferrer">
                      {item.title}
                    </a>
                  </a>
              ))}
              {/*{item.content.map((content, index) => (*/}
              {/*  <a key={`footercontent${index}`}>*/}
              {/*    {content === "Pitch Deck" ? (*/}
              {/*      <a*/}
              {/*        href="https://docsend.com/view/wge76dkc9jkx8r7q"*/}
              {/*        target="_blank"*/}
              {/*        rel="noopener noreferrer"*/}
              {/*      >*/}
              {/*        {content}*/}
              {/*      </a>*/}
              {/*    ) : (*/}
              {/*      content*/}
              {/*    )}*/}
              {/*  </a>*/}
              {/*))}*/}
            </TabContainer>
          ))}
        </TabsContainer>
      </FooterContainer>
      <FooterAuthority>
        <p>
          © 2023 <span>Hashrate</span>, All rights reserved.
        </p>
        <p>Terms of Service </p>
      </FooterAuthority>
    </FooterWrapper>
  );
};

export default Footer;
