import React from "react";
import { MinerWrapper, MinerContainer, MinerButton } from "./style";

const Miner = () => {
  return (
    <MinerWrapper>
      <MinerContainer>
        <h1>
          Want to invest in {" "}
          <span>BITCOIN MINING</span>
        </h1>
        <a target="_blank" href="https://gamma.io/ordinals/collections/bluewheel-mining">
        <MinerButton> Apply Now </MinerButton>
        </a>
      </MinerContainer>
    </MinerWrapper>
  );
};

export default Miner;
