import React, { useEffect, useState } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import styled from "styled-components";

import { faker } from "@faker-js/faker";
import {
  ButtonWrapper,
  StokeWrapper,
  StokeContainer,
  StokeContent,
  StokeTitle,
  StokeDesc,
  // StokeView,
  Content,
  StokeChart,
  MinerButton,
  MinerWrapperx,
  StokeChartx,
} from "./style";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const StokeCard = styled.div`
  max-width: 580px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  @media screen and (max-width: 1110px) {
    max-width: 100%;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  max-width: 1200px;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 95%;
  gap: 20px;
  @media screen and (max-width: 654px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    gap: 80px;
  }
`;

const ChartContainerx = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  max-width: 1200px;
  gap: 20px;

  @media screen and (max-width: 654px) {
    width: 95%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr;
    gap: 40px;
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
const StyledLink = styled.a`
  color: red;
  font-weight: bold;
`;

//https://api.bluewheelmining.com/datas/chart/stat.json here
interface ChartDataItem {
  time: number;
  hashrate: string;
  reject: string;
  validNum: number;
  transferOut: string;
  transferIn: string;
  total: string;
}

interface APIResponse {
  code: number;
  msg: string;
  data: {
    hashRate: string;
    hashRateTransferOut: string;
    hashRateTransferIn: string;
    dayHashRate: string;
    dayHashRateTransferOut: string;
    dayHashRateTransferIn: string;
    validNum: number;
    invalidNum: number;
    profitToday: {
      BTC: string;
      BCH: string;
      BSV: string;
    };
    profitYesterday: {
      BTC: string;
      BCH: string;
      BSV: string;
    };
    profitStatus: number;
    dayList: ChartDataItem[];
    hoursList: ChartDataItem[];
    switchList: any; // veya belirli bir tip, burada verilmediği için 'any' kullanıldı
  };
}

interface HourlyAPIResponse {
  code: number;
  msg: string;
  data: {
    hashRate: number;
    hashRateTransferOut: string;
    hashRateTransferIn: string;
    dayHashRate: number;
    dayHashRateTransferOut: string;
    dayHashRateTransferIn: string;
    validNum: number;
    invalidNum: number;
    profitToday: {
      BTC: number;
      BCH: number;
      BSV: number;
    };
    profitYesterday: {
      BTC: number;
      BCH: number;
      BSV: number;
    };
    profitStatus: number;
    dayList: ChartDataItem[];
    hoursList: ChartDataItem[];
    switchList: any; // veya belirli bir tip, burada verilmediği için 'any' kullanıldı
  };
}

interface ChartData {
  labels: string[];
  datasets: {
    fill: boolean;
    label: string;
    data: string[];
    borderColor: string;
    backgroundColor: string;
  }[];
}
const formatNumber = (number: number, decimalPlaces: number): string => {
  const formattedNumber = number.toFixed(decimalPlaces);
  return formattedNumber;
};

export const options = {
  aspectRatio: 1,
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: function (value: any) {
          if (Math.abs(value) >= 1.0e15) {
            return (value / 1.0e15).toFixed(2) + " PH/s";
          } else if (Math.abs(value) >= 1.0e12) {
            return (value / 1.0e12).toFixed(2) + " TH/s";
          } else if (Math.abs(value) >= 1.0e9) {
            return (value / 1.0e9).toFixed(2) + " GH/s";
          } else if (Math.abs(value) >= 1.0e6) {
            return (value / 1.0e6).toFixed(2) + " MH/s";
          } else {
            return value.toFixed(0);
          }
        },
      },
    },
  },
};

const Stoke = () => {
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [chartHourlyData, setHourlyChartData] = useState<ChartData | null>(
    null
  );
  const [validNum, setValidNum] = useState<number | null>(null);
  const [invalidNum, setInvalidNum] = useState<number | null>(null);
  const [profitTodayBTC, setProfitTodayBTC] = useState<number | null>(null);
  const [profitYesterdayBTC, setProfitYesterdayBTC] = useState<number | null>(
    null
  );
  const [dayListData, setdayListData] = useState<string | null>(null);
  const [hoursListData, setdayhoursListData] = useState<string | null>(null);

  const formatDate = (date: Date): string => {
    return date.toLocaleDateString();
  };

  useEffect(() => {
    axios
      .get<APIResponse, HourlyAPIResponse>(
        "https://api.bluewheelmining.com/datas/chart/stat.json"
      )
      .then((response) => {
        // console.log(response); check
        const dayList = response.data.dayList;
        const hoursList = response.data.hoursList;

        setValidNum(response.data.validNum);
        setInvalidNum(response.data.invalidNum);
        setProfitTodayBTC(response.data.profitToday.BTC);
        setProfitYesterdayBTC(response.data.profitYesterday.BTC);

        const formatHashRate = (hashRatex: string) => {
          const phRate = parseFloat(hashRatex);
          if (isNaN(phRate)) {
            return "N/A";
          }
          if (phRate >= 1e15) {
            return (phRate / 1e15).toFixed(2) + " Ph/s";
          } else if (phRate >= 1e12) {
            return (phRate / 1e12).toFixed(2) + " TH/s";
          } else if (phRate >= 1e9) {
            return (phRate / 1e9).toFixed(2) + " GH/s";
          } else if (phRate >= 1e6) {
            return (phRate / 1e6).toFixed(2) + " MH/s";
          } else if (phRate >= 1e3) {
            return (phRate / 1e3).toFixed(2) + " KH/s";
          } else {
            return phRate.toFixed(2) + " H/s";
          }
        };

        setdayhoursListData(formatHashRate(response.data.hashRate.toString()));
        setdayListData(formatHashRate(response.data.dayHashRate.toString()));

        // console.log(dayList); // dayList

        if (dayList) {
          const labels = dayList.map((item: ChartDataItem) => {
            const date = new Date(item.time);
            return formatDate(date);
          });

          const values = dayList.map((item: ChartDataItem) => {
            const formattedHashrate = formatNumber(
              parseFloat(item.hashrate),
              3
            );
            return formattedHashrate;
          });

          const newChartData: ChartData = {
            labels,
            datasets: [
              {
                fill: true,
                label: "Hashrate",
                data: values,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
              },
            ],
          };

          setChartData(newChartData);
        }

        if (hoursList) {
          const labels = hoursList.map((item: ChartDataItem) => {
            const date = new Date(item.time);
            const hour = date.getHours();
            return hour.toString().padStart(2, "0") + ":00";
          });

          const values = hoursList.map((item: ChartDataItem) => {
            const formattedHashrate = formatNumber(
              parseFloat(item.hashrate),
              3
            );
            return formattedHashrate;
          });

          const newHourlyChartData: ChartData = {
            labels,
            datasets: [
              {
                fill: true,
                label: "Hourly Hashrate",
                data: values,
                borderColor: "#3fcdc3",
                backgroundColor: "#103936",
              },
            ],
          };

          setHourlyChartData(newHourlyChartData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const ChartWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const Icon = styled.span`
    margin-right: 5px;
  `;

  const DataContainer = styled.span`
    width: 100%;
    text-align: center; /* İçerikleri merkeze hizalıyoruz */
  `;

  return (
    <>
      <ChartWrapper>
        <ChartContainer>
          <ChartWrapper>
            <DataContainer>
              <img src="/assets/icons/todayest.svg" alt="" />
              <div>
                <div>Gross Revenue Today (BTC) - Estimated</div>
                <div>{profitTodayBTC}</div>
              </div>
            </DataContainer>

            <DataContainer>
              <img src="/assets/icons/yestest.svg" alt="" />
              <div>
                <div>Gross Revenue Yesterday (BTC)</div>
                <div>{profitYesterdayBTC}</div>
              </div>
            </DataContainer>
          </ChartWrapper>
          <ChartWrapper>
            <DataContainer>
              <img src="/assets/icons/tick.svg" alt="" />
              <div>
                <div>Active Devices</div>
                <div>{validNum}</div>
              </div>
            </DataContainer>

            <DataContainer>
              <img src="/assets/icons/warning.svg" alt="" />
              <div>
                <div>Inactive Devices</div>
                <div>{invalidNum}</div>
              </div>
            </DataContainer>
          </ChartWrapper>
          <ChartWrapper>
            <DataContainer>
              <img src="/assets/icons/24h.svg" alt="" />
              <div>
                <div>24 Hour Hashrate</div>
                <div>{dayListData}</div>
              </div>
            </DataContainer>

            <DataContainer>
              <img src="/assets/icons/15d.svg" alt="" />
              <div>
                <div>15 Min Hashrate</div>
                <div>{hoursListData}</div>
              </div>
            </DataContainer>
          </ChartWrapper>
          {/* <StokeView>View</StokeView> 

        <StokeCard>
          <StokeContent>
            <Content>
              <StokeTitle>
                Bluewheel Ordinals Pool - Hashrate (Daily)
              </StokeTitle>
              <StokeDesc>
                This Bitcoin Mining pool displays latest block & total hashrate
                of Bluewheel Mining Ordinals Holders
              </StokeDesc>
              <StyledLink>This data is updated daily.</StyledLink>
            </Content>
            {/* <StokeView>View</StokeView> 
          </StokeContent>
          <StokeChart>
            {chartData ? (
              <Line options={options} data={chartData} />
            ) : (
              <p>Loading chart data...</p>
            )}
          </StokeChart>
        </StokeCard>

      */}

          {/* <StokeView>View</StokeView>

    <StokeCard>
      <StokeContent>
        <Content>
          <StokeTitle>Bluewheel Mining - Hashrate (Hourly)</StokeTitle>
          <StokeDesc>
            This Bitcoin pool displays cumulative mining of Bluewheel Mining
            Ltd. Including Bluewheel Personel devices & Ordinal devices.
          </StokeDesc>
          <StyledLink>Auto-updated data will be here very soon.</StyledLink>
        </Content>
      </StokeContent>
      <StokeChart>
        {chartData ? (
          <Line options={options} data={chartData} />
        ) : (
          <p>Loading chart data...</p>
        )}
      </StokeChart>
    </StokeCard>
    {/* <StokeView>View</StokeView>
    <StokeCard>
      <StokeContent>
        <Content>
          <StokeTitle>Bluewheel Mining - Hashrate (Daily)</StokeTitle>
          <StokeDesc>
            This Bitcoin pool displays cumulative mining of Bluewheel Mining
            Ltd. Including Bluewheel Personel devices & Ordinal devices.
          </StokeDesc>
          <StyledLink>Auto-updated data will be here very soon.</StyledLink>
        </Content>
      </StokeContent>
      <StokeChart>
        {chartData ? (
          <Line options={options} data={chartData} />
        ) : (
          <p>Loading chart data...</p>
        )}
      </StokeChart>
    </StokeCard>*/}
        </ChartContainer>
      </ChartWrapper>

      <ChartContainerx>
        <StokeChartx>
          {chartHourlyData ? (
            <Line options={options} data={chartHourlyData} />
          ) : (
            <p>Loading chart data...</p>
          )}
        </StokeChartx>
        <a>
          Disclaimer : Mentioned revenue is gross BTC revenue. Management charge
          and electricity charges would be deducted from Gross BTC revenue and
          balance would be sent to Ordinal holders.
        </a>
      </ChartContainerx>
      <MinerWrapperx>
        <a
          target="_blank"
          href="https://pool.binance.com/en/statistics?urlParams=m054H0psEUTMwymowb06q86KTPuNQWRzzNjzepBf0d807021"
        >
          <MinerButton>Live Pool</MinerButton>
        </a>
      </MinerWrapperx>
    </>
  );
};

export default Stoke;
