import React from "react";
import { AppLayout } from "../../layout/Index";
import LandingPage from "../../layout/landing/Index";
import { AiFillWarning } from "react-icons/ai";

const priceData = [
  {
    img: "assets/imgs/gif/1.gif",
    usd: "550",
    qty: "2300",
  },
];

const Nft = () => {
  return (
    <AppLayout>
      <div className="flex items-center justify-center mt-36 w-full">
        <div className="flex flex-col items-center  max-w-5xl w-[95%]">
        
          <div className="flex items-center justify-between gap-10 py-20 flex-wrap">
          <h1 className="text-[35px] font-semibold">About Us</h1>
            <a>
              Bluewheel Group, is a pioneering force in Bitcoin Mining and
              blockchain investments. With a robust operation managing over 3000
              Bitcoin ASIC miners, Bluewheel Mining exemplifies cutting-edge
              efficiency in cryptocurrency extraction. Complementing its mining
              prowess, Group has strategically invested in over 200 blockchain
              startups, driving innovation and growth in the digital finance
              sector. <br></br>
              The group's holistic approach seamlessly integrates technological
              advancements with strategic investments, positioning Bluewheel as
              a leader in the global cryptocurrency landscape.to power our
              operations.
            </a>
            <button className="bg-[#3fcdc3] text-black font-semibold px-5 py-5 rounded-lg">
              Explore Bluewheel Group
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Nft;
