import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Topbar from "../topbar/Index";

export const headerTab = [
  {
    title: "HASHRATE",
    direct: "/hashrate",
  },
  {
    title: "ABOUT US",
    direct: "/about",
    submenu: [
      { title: "About Us", direct: "/about" },
      { title: "Team", direct: "/team" },
      { title: "Partners", direct: "/ourpartners" },
    ],
  },
  {
    title: "OUR BUSINESS",
    direct: "/business",
    submenu: [
      { title: "Mining & Hosting Service", direct: "/" },
      { title: "Digital Mining (Ordinals)", direct: "/ordinals" },
      { title: "Investment", direct: "/" },
      { title: "OCT Desk", direct: "/" },
    ],
  },
  {
    title: "MEDIA",
    direct: "/media",
    submenu: [
      { title: "Press", direct: "/press" },
      { title: "Event", direct: "/event" },
    ],
  },
  {
    title: "CONTACT US",
    direct: "/contact",
  },
];

type HeaderProps = {
  onMenuOpen: () => void;
};

const Header: React.FC<HeaderProps> = ({ onMenuOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [header, setHeader] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) setHeader(true);
    else setHeader(false);
  };

  return (
    <div className={`fixed w-full h-[110px] flex flex-col items-center top-0 left-0 z-10 transition-all duration-400 ${header ? 'bg-[rgba(0,0,0,0.25)] backdrop-blur-lg shadow-lg' : 'bg-[#142c2a] shadow-md'}`}>
      <Topbar />
      <div className="max-w-[1200px] w-[95%] mx-auto flex items-start justify-between mt-5">
        <div onClick={() => navigate("/")} className="cursor-pointer w-[70px]">
          <img src="/assets/imgs/logo.png" alt="logo" className="w-full" />
        </div>
        <nav className="flex items-center hidden md:flex">
          {headerTab.length > 0 && headerTab.map((item, key) => (
            <div key={key} className="relative group">
              <Link
                to={item.direct}
                className={`text-white font-normal text-[15px] ml-9 cursor-pointer transition-all duration-300 ${location.pathname === item.direct ? 'text-[#3fcdc3]' : ''}`}
              >
                {item.title}
              </Link>
              {item.submenu && (
                <div className="absolute left-0 mt-2 w-[200px] bg-[#142c2a] rounded-md shadow-lg overflow-hidden opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  {item.submenu.map((subItem, subKey) => (
                    <Link
                      key={subKey}
                      to={subItem.direct}
                      className="block px-4 py-2 text-sm text-white hover:bg-[#3fcdc3] hover:text-[#142c2a] transition-all duration-300"
                    >
                      {subItem.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
          <a
            target="_blank"
            href="https://gamma.io/ordinals/collections/bluewheel-mining"
            className="ml-12 cursor-pointer w-[200px] h-[40px] flex items-center justify-center rounded-md text-[#142c2a] bg-[#3fcdc3] font-semibold text-[16px] transition-all duration-300"
          >
            Buy Ordinals
          </a>
        </nav>
        <div onClick={onMenuOpen} className="md:hidden flex flex-col gap-2 w-[30px] cursor-pointer">
          <span className="w-full h-[2px] rounded bg-white transition-all duration-300"></span>
          <span className="w-[50%] h-[2px] rounded bg-white transition-all duration-300"></span>
          <span className="w-full h-[2px] rounded bg-white transition-all duration-300"></span>
        </div>
      </div>
    </div>
  );
};

export default Header;
