import React from "react";
import {
  LandingWrapper,
  LandingContainer,
  LandingDesc,
  LandBtnOutside,
  LandBtnInside,
} from "./style";

const scrollToTop = () => {
  window.scrollTo({
    top: 800,
    behavior: 'smooth', // Bu, kaydırma işleminin yavaş ve düzgün bir şekilde gerçekleşmesini sağlar
  });
};



const LandingPage = () => {
  return (
    <LandingWrapper >
      {/* <img src="/assets/imgs/landing-2.png" alt="" /> */}
      <LandingContainer>
        <LandingDesc>
          <h1>
            Bitcoin runs on <span>Hashrate</span>
          </h1>
          <p>
          Bluewheel Group, is a pioneering force in Bitcoin Mining and blockchain investments. With a robust operation managing over 3000 Bitcoin ASIC miners, Bluewheel Mining exemplifies cutting-edge efficiency in cryptocurrency extraction. 
          </p>
        </LandingDesc>
        <LandBtnOutside>
          <LandBtnInside onClick={scrollToTop}>Swipe</LandBtnInside>
        </LandBtnOutside>
      </LandingContainer>
    </LandingWrapper>
  );
};

export default LandingPage;
