import React from "react";
import { Carousel } from 'react-responsive-carousel';
import '../../App.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import {
  PartnersWrapper,
  PartnersContainer,
  CardContainer,
  Card,
  CardDesc,
} from "./style";



const index = () => {

  const videos = [
    {
      title: 'Video 1',
      url: 'https://www.youtube.com/embed/kEozu65Qe5k',
    },
    {
      title: 'Video 2',
      url: 'https://www.youtube.com/embed/OQhUMGQuGaw',
    },
    {
      title: 'Video 3',
      url: 'https://www.youtube.com/embed/gGylOWeJgBA',
    },
    {
      title: 'Video 4',
      url: 'https://www.youtube.com/embed/-wbeLVbauQQ',
    },
  ];

  const isMobile = window.innerWidth <= 768; // Mobil cihazlar için 768 piksel altı genişlik kabul edilir.



  return (
   

      <PartnersWrapper>
        
        <PartnersContainer>
       
          <Carousel className={'asdasd'}
              showArrows={!isMobile} // Masaüstü için okları göster
              showThumbs={false} // Küçük resimleri gösterme
              showStatus={false} // Durum çubuğunu gösterme
              centerMode={!isMobile} // Masaüstünde merkezdeki öğeyi göster
              centerSlidePercentage={!isMobile ? 33.33 : 100} // Masaüstünde 3 öğe gösterme
              infiniteLoop={true} // Sonsuz döngü
              autoPlay={false} // Otomatik oynatma
              emulateTouch={true} // Mobil cihazlarda dokunmatik hareketleri etkisiz hale getir
              stopOnHover={false} // Fare üzerine gelindiğinde otomatik oynatmayı durdurma
              interval={!isMobile ? 10000 : 6000} // Otomatik oynatma aralığı
              transitionTime={1000} // Geçiş süresi
          >
            {videos.map((video, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'videos'} style={{ maxWidth: '1024px' }}>
                    <iframe
                        title={video.title}
                        width="100%"
                        height="240"
                        src={video.url}
                        frameBorder="0"
                        allowFullScreen
                    />
                  </div>
                </div>
            ))}
          </Carousel>
        </PartnersContainer>

      </PartnersWrapper>
  
  );
};

export default index;
