import React from 'react';
import {
  BsFacebook,
  BsYoutube,
  BsTwitter,
  BsLinkedin,
  BsDiscord,
  BsTelegram,
  BsMedium,
  BsFilePdfFill,
} from "react-icons/bs";

const SocialMediaIcon = () => {
  const socialMediaData = [ //Watch me :=)
    
    {
      name: 'Telegram',
      icon: BsTelegram,
      link: 'https://t.me/bluewheelminersann'
    },
    {
      name: 'Discord',
      icon: BsDiscord,
      link: 'https://discord.gg/bluewheelmining'
    },
    {
      name: 'Twitter',
      icon: BsTwitter,
      link: 'https://twitter.com/BluewheelM'
    },
    {
      name: 'Medium',
      icon: BsMedium,
      link: 'https://medium.com/@bluewheelmining'
    }, 
    
  ];

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '15px', // Sağa boşluk bırakmak için değeri ayarlayabilirsiniz
        transform: 'translateY(-50%)',
        display: 'flex',
        zIndex: '50',
        flexDirection: 'column',
        alignItems: 'flex-end', // İkonları sağa hizalamak için değiştirildi
      }}
    >
      {socialMediaData.map((item, index) => (
        <a
          key={index}
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginBottom: '15px' }} // İkonlar arasına boşluk bırakmak için değeri ayarlayabilirsiniz
        >
          {React.createElement(item.icon, { size: 30 })}
        </a>
      ))}
    </div>
  );
};

const Social = () => {
  return (
    <div style={{ position: 'relative', zIndex: 10 }}>
      {/* ... diğer bileşenler */}
      <SocialMediaIcon />
    </div>
  );
};

export default Social;
