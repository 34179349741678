import React from 'react';
import { HomeWrapper } from './style';
import { AppLayout } from '../../layout/Index';
import LandingPage from '../../layout/landing/Index';
import Rig from './Rig';
import Farm from './Farm';


const Rigs = () => {
  return (
    <AppLayout>
 
      <HomeWrapper>
        <Rig />
        <Farm />
         
      </HomeWrapper>
    </AppLayout>
  )
}

export default Rigs
