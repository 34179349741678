import React from "react";
import { CryptoWrapper, CryptoContainer, CryptoButton } from "./style";

const Crypto = () => {
  return (
    <CryptoWrapper>
      <CryptoContainer>
        <h1>
          Identify opportunities in <span>BUY BLUEWHEEL MINING ORDINALS</span>
        </h1>
        <a target="_blank" href="https://gamma.io/ordinals/collections/bluewheel-mining">
        <CryptoButton>BUY Ordinals</CryptoButton></a>
      </CryptoContainer>
    </CryptoWrapper>
  );
};

export default Crypto;
