import React, { useState } from "react";
import { HomeWrapper } from "./style";
import { AppLayout } from "../../layout/Index";
import LandingPage from "../../layout/landing/Index";
import Article from "../../components/article";
import Miner from "./Miner";
import Crypto from "./Crypto";
import Hashrate from "../../components/Hashrate";
import Rig from "./Rig";
import Stoke from "../../components/chartsNew";
import Farm from "./Farm";
import World from "./World";
import Calc from "../../components/Calc";
import Contact from "./Contact";

import Videos from "../../pages/videos/index";
import Partners from "../../pages/ourpartners/index";

const Home = () => {
  const [shouldScrollToArticle, setShouldScrollToArticle] = useState(false);

  return (
    <AppLayout>
      <LandingPage />

      <HomeWrapper>
        <Article />
        <Miner />
   
        <Crypto />
        <Farm />
        <Videos />
        <Partners />
        <World />
        <Calc />
        <Contact />
       
      </HomeWrapper>
    </AppLayout>
  );
};

export default Home;
