import React from "react";
import { HomeWrapper } from "./style";
import { AppLayout } from "../../layout/Index";
import Hashrate from "../../components/Hashrate";
import Stoke from '../../components/chartsNew';
import CheliaS19VICK from "../../components/CheliaS19VICK";
import CheliaM30S100TH from "../../components/CheliaM30S100TH";
import CheliaM31S from "../../components/CheliaM31S";
import M50118Chelia20 from "../../components/M50118Chelia20";
import GoDomedovoAvalon100 from "../../components/GoDomedovoAvalon100";
import GoDomedovobat2Ava65 from "../../components/GoDomedovobat2Ava65";


const HashratePage = () => {
  return (
    <AppLayout>
      <HomeWrapper>
        <Hashrate />
        <Stoke />  
       {/* <CheliaS19VICK />
        <CheliaM30S100TH /> 
        <CheliaM31S /> 
        <M50118Chelia20 /> 
        < GoDomedovoAvalon100/>
        < GoDomedovobat2Ava65/>
          */}
          
      </HomeWrapper>
    </AppLayout>
  );
};

export default HashratePage;
