import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  RoadmapWrapper,
  RoadmapContainer,
  MemberShipsContainer,
  MemberShipCard,
  CardTitle,
  CardContent,
  CardList,
  MemberShipOut,
} from "./style";
import { AppLayout } from "../../layout/Index";
import LandingPage from "../../layout/landing/Index";

const cardData = [
  {
    mark: "assets/membership.svg",
    title: "Phase 01",
    getList: [
      {
        text: "Purchase of 400 Asic miners – S19 Pro 120th or equivalent",
        showCheckmark: true,
      },
      { text: "Setup of Miners at Data center", showCheckmark: true },
      {
        text: "Launch of custom Ordinals Contract with distribution of BTC Rewards",
        showCheckmark: false,
      },
    ],
  },
  {
    mark: "assets/membership.svg",
    title: "Phase 02",
    getList: [
      { text: "Launch of Ordinal Lending", showCheckmark: false },
      { text: "Launch of Ordinal Renting", showCheckmark: false },
      {
        text: "Physical Audit of Infrastructure by Reputed Auditor",
        showCheckmark: false,
      },
      {
        text: "Ordinals for additional 1200 Asic miners",
        showCheckmark: false,
      },
      {
        text: "Partnership with HNWIs & Traditional VCs for expansion of Mining Capacity",
        showCheckmark: false,
      },
      { text: "Setup of own Bitcoin Data Center", showCheckmark: false },
    ],
  },
  {
    mark: "assets/membership.svg",
    title: "Phase 03",
    getList: [
      {
        text: "Physical Audit of Infrastructure by Reputed Auditor",
        showCheckmark: false,
      },
      {
        text: "Ordinals for additional 1800 ASIC miners",
        showCheckmark: false,
      },
      { text: "Listing on reputable Stock Exchange", showCheckmark: false },
    ],
  },
];

const Roadmap = () => {
  return (
    <AppLayout>
      <RoadmapWrapper>
        <RoadmapContainer>
          <MemberShipOut>
            <h1>
              Bluewheel Mining <span>Roadmap</span>
            </h1>
            <MemberShipsContainer>
              {cardData.map((item, key) => (
                <MemberShipCard key={`membercard${key}`}>
                  <CardTitle>
                    <div>
                      <img src={item.mark} alt="" />
                      {item.title}
                    </div>
                  </CardTitle>
                  <CardContent>
                    <CardList>
                      {item.getList.map((listItem, listKey) => (
                        <div key={`membercardgetlist${listKey}`}>
                          {listItem.showCheckmark ? (
                            <AiFillCheckCircle color="green" />
                          ) : (
                            <AiFillCheckCircle color="#fff" />
                          )}
                          {listItem.text}
                        </div>
                      ))}
                    </CardList>
                  </CardContent>
                </MemberShipCard>
              ))}
            </MemberShipsContainer>
          </MemberShipOut>
        </RoadmapContainer>
      </RoadmapWrapper>
    
    </AppLayout>
  );
};

export default Roadmap;
