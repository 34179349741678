import React, { useEffect, useState } from "react";
import {
  BlogWrapper,
  PitchContainer,
  PitchContentContainer,
  PitchSubTitle,
  PitchTitle,
  PitchMan,
  ArticleButton,
  CardWrapper,
  Card,
  CardBtn,
  CardContent,
  ArticleContainer,
} from "./style";
import { AppLayout } from "../../layout/Index";
import { BsArrowUpRight } from "react-icons/bs";
import LandingPage from "../../layout/landing/Index";
import axios from "axios";


const Blog = () => {
  const [articledata, setArticleData] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://raw.githubusercontent.com/salimcansatici/mock-data/main/article.data.json"
      )
      .then((response) => {
        setArticleData(response.data);
      })
      .catch((error) => {
        console.error("Error data getting...:", error);
      });
  }, []);

  return (
    <AppLayout>
      <BlogWrapper>
        <PitchContainer>
          <img
            src="assets/imgs/artweficle.png"
            alt=""
            className="pitch-img"
            placeholder="blur"
          />
          <PitchContentContainer>
            <PitchSubTitle>
              <span>Category</span>November 22, 2021
            </PitchSubTitle>
            <PitchTitle>
              Pitch termsheet backing validation focus release.
            </PitchTitle>
            <PitchMan>
              <img src="assets/imgs/author.png" alt="" placeholder="blur" />
              <span>Chandler Bing</span>
            </PitchMan>
          </PitchContentContainer>
        </PitchContainer>
        <ArticleContainer>
          <CardWrapper>
          {articledata.map((item: any, key: number) => (
        <Card key={key}>
          <img src={item.image} alt="" />
          <CardContent>
            <h6>{item.title}</h6>
            <p>{item.desc}</p>
            <CardBtn>
              Read More <BsArrowUpRight />
            </CardBtn>
          </CardContent>
        </Card>
      ))}
          </CardWrapper>
        </ArticleContainer>
        <ArticleButton>Load More</ArticleButton>
    
      </BlogWrapper>
    </AppLayout>
  );
};

export default Blog;
